<template>
  <v-container class="contenedor-main">
    <v-data-table
      :headers="headers"
      :items="listaCursos"
      :search="search"
      no-results-text="Sin resultados"
      sort-by="Nombre"
      class="elevation-1"
      :header-props="{ sortByText: 'Organizar por' }"
      :footer-props="{
        'items-per-page-text': 'items por página',
        'items-per-page-all-text': 'Todos',
        'itmes-per-page-options': [-1, 5, 10, 15],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Cursos</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                small
              >
                Nuevo curso
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{
                  editar == false
                    ? "Crear nuevo curso"
                    : "Editar notas del curso " +
                      editedItem.cursoBase +
                      " - " +
                      editedItem.periodo
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col v-if="!editar" cols="12" sm="4" md="4">
                      <v-autocomplete
                        v-model="editedItem.cursoBase"
                        :items="listaMaterias"
                        @change="info"
                        :append-icon="
                          listaMaterias.length == 0 ? 'mdi-reload' : ''
                        "
                        chips
                        clearable
                        item-value="nombre"
                        item-text="nombreCompleto"
                        label="Curso base (materia)"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Este curso base no existe
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="!editar" cols="12" sm="4" md="4">
                      <v-autocomplete
                        v-model="editedItem.periodo"
                        :items="listaPeriodos"
                        chips
                        clearable
                        hide-details
                        item-value="idP"
                        item-text="idP"
                        label="Periodos"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Este periodo no existe
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="!editar" cols="12" sm="4" md="4">
                      <v-autocomplete
                        v-model="editedItem.docente"
                        :items="listaDocentes"
                        chips
                        clearable
                        hide-details
                        item-value="id"
                        item-text="nombre"
                        label="Docentes"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              Este curso base no existe
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.nombre"
                            ></v-list-item-title>
                            <v-list-item-subtitle v-text="item.correo">
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>

                        <template v-slot:selected="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="item.nombre"
                            ></v-list-item-title>
                            <v-list-item-subtitle v-text="item.correo">
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="[4, 5, 6, 7, 8, 9]"
                        v-model="editedItem.cantidadDeNotas"
                        @change="editedItem.porcentajeNotas = []"
                        placeholder="No. de notas"
                      >
                      </v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      v-for="(data, index) in editedItem.cantidadDeNotas"
                      :key="index"
                    >
                      <v-text-field
                        v-model="editedItem.porcentajeNotas[index]"
                        label="Puntos porcentuales: "
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" text @click="close"> Cancelar </v-btn>
                <v-btn
                  :disabled="buscando"
                  color="primary"
                  text
                  @click="editar == false ? save() : editaNotas()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Deseas borrar este curso?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="accent" text @click="closeDelete">Cancelar</v-btn>
                <v-btn
                  :disabled="buscando"
                  color="primary"
                  text
                  @click="deleteItemConfirm"
                >
                  SI</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.recibeTrabajos`]="{ item }">
        <v-switch
          inset
          v-model="item.recibeTrabajos"
          color="primary"
          @click="noTrabajo(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.profesoresHabilitados`]="{ item }">
        <v-autocomplete
          v-bind:style="{ 'margin-top': '4%' }"
          v-model="item.profesoresHabilitados"
          :items="profesTotal"
          label="Lista docentes"
          @change="habilitaDocente(item)"
          multiple
          clearable
          item-text="nombre"
          item-value="id"
          dense
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title> Este curso no existe </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.nombre"></v-list-item-title>
              <v-list-item-subtitle v-text="item.correo"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn @click="deleteItem(item)" plain icon>
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
        <v-btn @click="editItem(item)" plain icon>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-progress-circular
          class="contenedor-tools__progress"
          v-if="buscando == true"
          :width="3"
          :size="20"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import data from "./Tools/data";

export default {
  data() {
    return {
      search: "",
      listaMaterias: [],
      listaPeriodos: [],
      listaCursos: [],
      listaDocentes: [],
      profesTotal: [],
      editar: false,
      profesSelected: [],
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
        },
        { text: "Nombre curso", value: "cursoBase" },
        { text: "Periodo", value: "periodo" },
        { text: "Docente", value: "profesoresHabilitados", sortable: true },
        { text: "Reciben trabajos", value: "recibeTrabajos" },
        { text: "", value: "actions" },
      ],
      editedItem: {
        nombre: "",
        cursoBase: "",
        periodo: "",
        docente: "",
        cantidadDeNotas: "",
        profesoresHabilitados: [],
        porcentajeNotas: [],
      },
      defaultItem: {
        nombre: "",
        cursoBase: "",
        periodo: "",
        docente: "",
        cantidadDeNotas: "",
        porcentajeNotas: [],
      },
      editedIndex: -1,
      dialogDelete: false,
      dialog: false,
      idC: null,
      active: false,
      buscando: false,
    };
  },

  async created() {
    document.title = "Cursos";
    // Materias
    if (this.$store.state.materias.length == 0) {
      this.listaCursos = await data.dataList(
        "/cursos",
        "lista",
        "listar",
        "materias"
      );
    } else {
      this.listaCursos = this.$store.state.materias;
    }
    // PERIODOS
    if (this.$store.state.periodos.length == 0) {
      this.listaPeriodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
    } else {
      this.listaPeriodos = this.$store.state.periodos;
    }
    // Cursos base
    if (this.$store.state.cursos.length == 0) {
      this.listaMaterias = await data.dataList(
        "/cursosBase",
        "lista",
        "listar",
        "cursos"
      );
    } else {
      this.listaMaterias = this.$store.state.cursos;
    }
  },

  mounted() {
    this.docentesGet();
    // this.cargueCursos();
  },

  methods: {
    async cargueCursos() {
      this.listaCursos = [];
      console.log(this.listaCursos);
      this.listaCursos = await data.dataList(
        "/cursos",
        "lista",
        "listar",
        "materias"
      );
      console.log(this.listaCursos);
    },

    habilitaDocente(item) {
      var profes = [];
      for (var a = 0; a < item.profesoresHabilitados.length; a++) {
        for (var h = 0; h < this.listaDocentes.length; h++) {
          if (item.profesoresHabilitados[a] == this.listaDocentes[h].nombre) {
            profes.push(this.listaDocentes[h].id);
          }
        }
      }
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "asignar_docente");
          fd.append("cursoId", item._id);
          fd.append("docentesId", profes);
          axios
            .post(host + "/cursos", fd)
            .then((result) => {
              let data = result.data;
              if (data.text == "OK") {
                this.$alert(
                  "Se han agregado los nuevos docentes satisfactoriamente"
                );
              }
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
        });
    },

    docentesGet() {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          axios.post(host + "/listaUsuarios", fd).then((result) => {
            let data = result.data.lista;
            // console.log('profes ' + JSON.stringify(data))
            for (let i = 0; i < data.length; i++) {
              if (data[i]["isAdmin"] == 2) {
                this.listaDocentes.push({
                  nombre:
                    data[i]["primerNombre"] + " " + data[i]["primerApellido"],
                  id: data[i]["userId"],
                  correo: data[i]["email"],
                });
                this.profesTotal.push({
                  nombre:
                    data[i]["primerNombre"] + " " + data[i]["primerApellido"],
                  correo: data[i]["email"],
                });
              }
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.listaCursos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.idC = item.nombre;
    },

    deleteItemConfirm() {
      this.buscando = true;
      this.listaCursos.splice(this.editedIndex, 1);
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "eliminar");
          fd.append("nombre", this.idC);
          axios.post(host + "/cursos", fd).then((result) => {
            this.buscando = false;
            let data = result.data.text;
            if (data == "OK") {
              this.listaMaterias.splice(this.editedIndex, 1);
              this.closeDelete();
            } else {
              this.$alert("Error " + data);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.buscando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "agregar");
          fd.append("periodo", this.editedItem.periodo);
          fd.append("cursoBase", this.editedItem.cursoBase);
          fd.append("cantidadNotas", this.editedItem.cantidadDeNotas);
          fd.append("porcentajeNotas", this.editedItem.porcentajeNotas);
          fd.append("docenteId", this.editedItem.docente);
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data.text;
            this.buscando = false;
            if (data == "OK") {
              this.close();
              this.cargueCursos();
            } else {
              this.$alert(
                "Error " +
                  "No se guardo la información del curso correctamente, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    info() {
      const mat = this.listaMaterias;
      console.log(this.listaMaterias);
      console.log(this.editedItem.cursoBase);
      for (var u = 0, len = mat.length; u < len; u++) {
        if (mat[u]["nombre"] == this.editedItem.cursoBase) {
          console.log("entre");
          this.editedItem.cantidadDeNotas = mat[u]["cantidadDeNotas"];
          this.editedItem.porcentajeNotas = mat[u]["porcentajeNotasPred"];
        }
      }
    },

    noTrabajo(item) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "habilita_trabajos");
          fd.append("periodo", item.periodo);
          fd.append("cursoBase", item.cursoBase);
          fd.append("habilita", item.recibeTrabajos);
          axios.post(host + "/cursos", fd).then((result) => {
            let data = result.data.text;
            if (data != "OK") {
              this.$alert(
                "Error " +
                  "No se guardo la información del curso correctamente, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    editItem(item) {
      this.dialog = true;
      this.editar = true;
      this.editedItem = item;
    },

    editaNotas() {
      this.buscando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "editar_porcentaje_notas");
          fd.append("cursoId", this.editedItem["_id"]);
          fd.append("cantidadNotas", this.editedItem.cantidadDeNotas);
          fd.append("porcentajeNotas", this.editedItem.porcentajeNotas);
          axios.post(host + "/cursos", fd).then((result) => {
            this.buscando = false;
            let data = result.data.text;
            if (data == "OK") {
              this.close();
              this.cargueCursos();
            } else {
              this.$alert("Error " + result.data.text);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },
  },
};
</script>
